<template>
    <div v-if="percentage !== null" :class="`alert alert-${colour}`">
        <Icon v="user-chart" />
        <T :params="{ item, percentage: `${percentage}%` }">census.stats.{{ type }}</T>
        <br>
        <small><T :params="{ edition, year: edition!.slice(-4) }">census.stats.source</T></small>
    </div>
</template>

<script lang="ts">
import { useAsyncData } from 'nuxt/app';
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';
import { fetchJson } from '../src/fetchJson.ts';

export default defineComponent({
    props: {
        type: { required: true, type: String },
        item: { required: true, type: String },
        colour: { default: 'light', type: String },
    },
    setup(props) {
        const config = useConfig();

        const { data: percentage } = useAsyncData(async () => {
            if (!config.census.enabled || !config.census.latestResults) {
                return null;
            }
            try {
                return await fetchJson(
                    `${config.census.latestResults}/general/stats.json`,
                    `${props.type}.${props.item}`,
                );
            } catch (error) {
                console.error(error);
                return null;
            }
        });

        return {
            edition: config.census.latestResults,
            percentage,
        };
    },
});
</script>
